.App {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  box-sizing: border-box;
}

.exelab-listskus ul {
  list-style: none;
  padding: 10px;
  box-sizing: border-box;
}

.exelab-listskus ul li {
  margin-bottom: 10px;
}

.exelab-skudetail-name {
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
}

.exelab-skudetail-description {
  font-size: 12px;
  font-style: italic;
}

.exelab-skudetail {
  position: relative;
}

.exelab-skudetail-code {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
}

.exelab-listorders ul {
  list-style: none;
  padding: 10px;
  box-sizing: border-box;
}

.exelab-listorders ul li {
  margin-bottom: 10px;
}




.pocwrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  background-color: lightgray;
  padding: 10px;
  box-sizing: border-box;
}

.mainPage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 250px;
  right: 0;
}

.mainHeader {
  position: absolute;
  top: 0;
  height: 100px;
  left: 0;
  right: 0;
  background-color: lightgray;
  padding-top: 25px;
  box-sizing: border-box;
}

.mainContent {
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}

.sidebarHeader {
  position: relative;
  top: 0;
  width: 100%;
  padding-bottom: 80px;
  background-image: url(https://f.hubspotusercontent30.net/hubfs/3963388/__hs-marketplace__/Frame%206-2-1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 10px;
}


.sidebarButtons {
  position: relative;
}

.sidebarButtons a {
  position: relative;
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  border-left: 2px solid transparent;
  margin-bottom: 10px;
  text-decoration: none;
  color: black;
}

.sidebarButtons a:hover, .sidebarButtons a.active {
  border-color: black;
}


.mainHeader-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}

.mainHeader-subTitle {
  font-size: 14px;
  font-style: italic;
  line-height: 20px;
}

.mainHeader-logOut {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: gray;
  cursor: pointer;
}

.mainHeader-logOut:hover {
  color: black;
}

.pocwrapper.loading:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(255, 255, 255, 0.5);
  cursor: wait;
}

.pageError {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: red;
  font-size: 14px;
  color: white;
  line-height: 20px;
  padding: 10px;
  box-sizing: border-box;
}




.exelab-orderdetail {
  display: flex;
  line-height: 20px;
}

.exelab-orderdetail-ordernumber {
  width: 150px;
  flex-grow: 0;
  flex-shrink: 0;
}

.exelab-orderdetail-status {
  width: 80px;
  flex-grow: 0;
  flex-shrink: 0;
}

.exelab-orderdetail-payment_status {
  width: 160px;
  flex-grow: 0;
  flex-shrink: 0;
}

.exelab-orderdetail-guest {
  width: 50px;
  flex-grow: 0;
  flex-shrink: 0;
}

.exelab-orderdetail-customer_email {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

.exelab-orderdetail-header {
  font-weight: bold;
}
.exelab-orderdetail > div + div {
  margin-left: 10px;
}



.exelab-orderdetail-attributes > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  line-height: 20px;
  font-size: 15px;
  border-bottom: 1px solid lightgray;
}

.exelab-orderdetailpage {
  position: relative;
  padding: 10px;
  box-sizing: border-box;
}

.exelab-orderdetail-attributes {
  position: relative;
  width: 100%;
  max-height: 300px;
  padding-right: 10px;
  box-sizing: border-box;
  overflow-y: auto;
} 